import * as React from "react";
import { Stack, Typography, Box, Container, Link } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Image } from "mui-image";

import logo from "../images/Tralexho-O.png";

dayjs.extend(localizedFormat)
dayjs.locale("en-in")

const theme = createTheme({
    maxWidth: "100vw",
    palette: {
        primary: {
            main: '#ffffff',
        },
    },
    typography: {
        h6: {
            fontWeight: 'bold',
        },
    },
});


function TraceV2({ open, orgInfo, traces }) {

    const getFormattedUrl = (url) => {
        // If the URL doesn't already start with http:// or https://, prepend https://
        if (!/^https?:\/\//i.test(url)) {
          return `https://${url}`;
        }
        return url;
    };

  return (
        <ThemeProvider theme={theme}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{ width: '100%' }}>
                <Stack direction="column" justifyContent="center" alignItems="center"
                    sx={{ height: { sm: "200px", md: "225px", lg: "250px" }, width: "100%" }}>
                    <Image src={orgInfo.companyBannerImagePath} alt="banner" style={{ objectFit: "fill", width: "100%", borderRadius: "5px", maxHeight: "100%" }} />
                </Stack>
                <Container maxWidth="md" id="org-info" sx={{ py: 1, px: 3, backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: 3, mt: 2, alignItems: "center" }}>
                    <Stack direction={{ xs: 'column', md: 'row', alignItems: "center" }} spacing={2}>
                        <Box
                            component="img"
                            src={orgInfo.logoPath}
                            alt=""
                            sx={{ width: { xs: '100%', md: '200px' }, height: 'auto', alignSelf: 'center' }}
                        />
                        <Stack direction="column" flex={1} spacing={1} sx={{ alignItems: "center"}}>
                            <Typography variant="subtitle1">{orgInfo.tagLine}</Typography>
                            <Typography variant="body2">{orgInfo.storyLine}</Typography>
                        </Stack>
                    </Stack>
                </Container>
                <Typography variant="h5" sx={{ mt: 2 }}>Story of Your Product</Typography>
                <Timeline sx={{ width: '100%', px: { xs: 1, sm: 2 } }}>
                    {traces && traces.map((item, idx) => (
                        <TimelineItem key={idx} sx={{ pt: 0, mt: 0 }}>
                            <TimelineOppositeContent sx={{ width: { xs: 80, sm: 110 }, py: 1, px: 0 }}>
                                <Stack direction="column" justifyContent="right" alignItems="right">
                                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{item.userEvent}</Typography>
                                    <Typography variant="caption">{item.eventTime}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "capitalize" }}>{item.location}</Typography>
                                </Stack>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ p: 0, m: 1, border: "none", boxShadow: "none", backgroundColor: "white" }}>
                                    <img src={logo} style={{ width: "25px" }} alt="tralexho" />
                                </TimelineDot>
                                {(idx < traces.length - 1) && <TimelineConnector sx={{ backgroundColor: "#000000" }} />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Stack direction="column" justifyContent="right" alignItems="right" sx={{ mb: 1 }}>
                                    <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>{item.productClass}</Typography>
                                    <img src={item.thumbnail} style={{ width: 180 }} alt="trace" />
                                </Stack>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
                <Link href={getFormattedUrl(orgInfo.companyWebsite)} target="_blank" rel="noopener noreferrer">
                    <Typography variant="caption" sx={{ mt: 1, color: '#000000' }}>
                        {orgInfo.companyWebsite}
                    </Typography>
                </Link>
                <Typography variant="caption" sx={{ mb: 2, mt: 1 }}>
                    Traceability powered by 
                    <Link  href="https://tralexho.com/" target="_blank" rel="noopener noreferrer" sx={{color:"#000000", textDecoration: "none"}}>
                    &nbsp;TRALEXHO
                    </Link>
                </Typography>
            </Stack>
        </ThemeProvider>
  );
}

export default TraceV2;
