import './App.css';
import { Route, Routes } from "react-router-dom";
import * as React from "react";
import Trace from './Trace';

function App() {
  
  return (

    <Routes>
      <Route path="/" element={
          <Trace />
        }/>
    </Routes>
  );
}

export default App;
