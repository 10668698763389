import { useLocation } from "react-router-dom";
import * as React from "react";
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { getLocation, getProductClass } from "./events_master";
import { traceEvents } from "./api";
import TraceV2 from "./components/TraceV2";
import TraceV1 from "./components/TraceV1";
import { Backdrop, CircularProgress } from "@mui/material";

dayjs.extend(localizedFormat)
dayjs.locale("en-in")

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const endpointKey = {
    "ardra-0": "https://r1b1esqqa5.execute-api.ap-south-1.amazonaws.com",
    "ardra-1": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
    "taas-dev": "https://mtbjlz77ff.execute-api.ap-south-1.amazonaws.com",
    "taas-staging": "https://b57yoc3peh.execute-api.ap-south-1.amazonaws.com",
    "taas-prod": "https://vajo2y8mnk.execute-api.ap-south-1.amazonaws.com",
}

export default function Trace() {
    let query = useQuery();
    const trace_item = query.get("trace_item");
    const trace_item_id = query.get("trace_item_id");
    const org_id = query.get("org_id");
    // Ardra Organics: 0
    // Seafood: 1
    // Events V2: none
    const env = query.get("env");
    const [open, setOpen] = React.useState(true);
    const [traces, setTraces] = React.useState([]);
    const [orgInfo, setOrgInfo] = React.useState([]);

    React.useEffect(() => {
        if (!trace_item && !trace_item_id) {
            window.location.href = "https://tralexho.com/";
            return;
        }

        const getTraces = async () => {
            let base_url, base_key;
            let results = [];
            let tmpResults = [];

            // ardra flow
            if(trace_item) {
                base_key = "ardra";
                base_url = endpointKey[`${base_key}-${env}`];
                results = await traceEvents(base_url, trace_item, "adra-organics");
                const tmpResults = [];
                for (const res of results) {
                    const productClass = getProductClass(res.output_product_instances.split("-")[0]);
                    const eventTime = dayjs(res.event_start_unix_epoch * 1000)
                    const item = {
                        "userEvent": res.user_event,
                        "eventDay": eventTime.format("L"),
                        "eventTime": eventTime.format("h:mm A"),
                        "location": getLocation(res.location).toLowerCase(),
                        "productClass": productClass.toLowerCase(),
                        "thumbnail": res.image_thumbnail_presigned_url
                    };
                    tmpResults.push(item);
                }
                setTraces(tmpResults);
            } else {
                base_key = "taas";
                base_url = endpointKey[`${base_key}-${env || "prod"}`];
                results = await traceEvents(base_url, trace_item_id, org_id);
                const { trace_events_list: traceEventsList } = results[1];
                for (let i = traceEventsList.length - 1; i > -1; i -= 1) {
                    const traceEvent = traceEventsList[i];
                    const eventTime = dayjs(traceEvent.event_start_unix_epoch * 1000)
                    const item = {
                        userEvent: traceEvent.user_event,
                        eventStartUnixEpoch: traceEvent.event_start_unix_epoch,
                        eventTime: eventTime.format("ddd, DD MMM YYYY h:mm A"),
                        location: traceEvent.location,
                        productClass: traceEvent.output_products,
                        thumbnail: traceEvent.image_thumbnail_presigned_url
                    };
                    tmpResults.push(item);
                }
                tmpResults.sort((a,b) => a.eventStartUnixEpoch - b.eventStartUnixEpoch);
                setTraces(tmpResults);
                setOrgInfo({ ...results[0].org_info });
            }

            setOpen(false);
        }
        getTraces();
    }, [env, org_id, trace_item, trace_item_id])
    
    const renderTrace = () => {
        if(trace_item_id)
            return <TraceV2 open={open} traces={traces} orgInfo={orgInfo} />;
        return <TraceV1 open={open} traces={traces} />;

    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {renderTrace()}
        </>
    )
}
