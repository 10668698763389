import pRetry from "p-retry";


const run = async (url, options) => {
    const response = await fetch(url, options);
    return response.json();
}

const fetchRetry = async (url, options) => {
    return await pRetry(() => run(url, options), { 
        retries: 5,
        onFailedAttempt: error => {
            console.log(`Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`);
            // 1st request => Attempt 1 failed. There are 4 retries left.
            // 2nd request => Attempt 2 failed. There are 3 retries left.
            // …
        },
    });
}
export const traceEvents = async (base_url, trace_item, orgId) => {
    const url = base_url + "/traces?trace_item_id=" + trace_item + "&org_id=" + orgId;
    const options = {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
    };
    return await fetchRetry(url, options);
}