import React from "react";
import banner from '../images/ardra_organics_traceability_banner.jpg';
import { Stack, Typography } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import "dayjs/locale/en-in";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import logo from "../images/Tralexho-O.png";

dayjs.extend(localizedFormat)
dayjs.locale("en-in")

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#242424',
        },
    },
});

function TraceV1({ open, traces }) {
    console.log(open);
    console.log(traces);
  return (
        <ThemeProvider theme={theme}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
                <img src={banner} style={{ width: "100%", borderRadius: "5px" }} alt="banner" />
                <Timeline>
                    {traces && traces.map((item, idx) => (
                        <TimelineItem key={idx}  >
                            <TimelineOppositeContent color="secondary" sx={{ width: 110, py: 1, px: 0 }}>
                                <Stack direction="column" justifyContent="right" alignItems="right">
                                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{item.userEvent}</Typography>
                                    <Typography variant="caption">{item.eventDay}</Typography>
                                    <Typography variant="caption">{item.eventTime}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "capitalize" }}>{item.location}</Typography>

                                </Stack>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" sx={{ p: 0, m: 1, border: "none" }}>
                                    <img src={logo} style={{ width: "25px" }} alt="tralexho" />
                                </TimelineDot>
                                {(idx < traces.length - 1) && <TimelineConnector sx={{ backgroundColor: "#242424" }} />}
                            </TimelineSeparator>
                            <TimelineContent>
                                <Stack direction="column" justifyContent="right" alignItems="right" sx={{ mb: 1 }}>
                                    <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>{item.productClass}</Typography>
                                    <img src={item.thumbnail} style={{ width: 180 }} alt="trace" />
                                </Stack>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>

            </Stack>
        </ThemeProvider>
    );
}

export default TraceV1;
